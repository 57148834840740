/*geihui_module - http://iget.vip/geihui_module/output*/
/*sass ver.201605271342*/
/*sass class*/
/*base color*/
$colorOrange: #ff6600 !default;
$colorOrangeYellow: #ff9900 !default;
$colorGreen: #94ce41 !default;
$colorRed: #bd0008 !default;
$colorBlue: #004fa6 !default;
$colorPink: #f90056 !default;
$colorGray33: #333 !default;
$colorGray66: #666 !default;
$colorGray88: #888 !default;
$colorGray99: #999 !default;
$colorGrayCC: #ccc !default;
$colorGrayE5: #e5e5e5 !default;
$colorGrayF7: #f7f7f7 !default;
$colorGrayF8: #f8f8f8 !default;

/*背景色-橙色*/
$baseBgOrange: $colorOrange !default;
/*背景色-橙色浅*/
$baseBgTitOrange: $colorOrangeYellow !default;
/*背景色-绿色*/
$baseBgGreen: $colorGreen !default;
/*背景色-红色*/
$baseBgRed: $colorRed !default;
/*背景色-蓝色*/
$baseBgBlue: $colorBlue !default;
/*背景色-粉色*/
$baseBgPink: $colorPink !default;
/*背景色-灰色*/
$baseBgGray: $colorGray99 !default;
/*背景色-灰色淡*/
$baseBgGrayS: $colorGrayE5 !default;
/*背景色-页面灰底色*/
$baseBgPageGray: $colorGrayF8 !default;
/*背景色-hover灰色*/
$baseBgHoverGray: $colorGrayF7 !default;
/*边框色-红色*/
$baseBorderRed: $colorRed !default;
/*文字-默认色*/
$baseColorDefault: $colorGray66 !default;
/*文字-标题黑色*/
$baseColorTitBlack: $colorGray33 !default;
/*文字-橙色*/
$baseColorOrange: $colorOrange !default;
/*文字-橙黄色*/
$baseColorOrangeYellow: $colorOrangeYellow !default;
/*文字-绿色*/
$baseColorGreen: $colorGreen !default;
/*文字-粉色*/
$baseColorPink: $colorPink !default;
/*文字-红色*/
$baseColorRed: $colorRed !default;
/*文字-灰色*/
$baseColorGray: $colorGray88 !default;
/*文字-淡灰色*/
$baseColorGrayS: $colorGrayCC !default;
/*边框色-灰色*/
$baseBorderGray: $colorGrayE5 !default;
/*边框色-灰色深*/
$baseBorderGrayB: $colorGrayCC !default;
/*边框色-橙色*/
$baseBorderOrange: $colorOrange !default;
/*边框色-橙黄色*/
$baseBorderOrangeYellow: $colorOrangeYellow !default;
/*阴影-深灰色*/
$baseShadowGray: $colorGray88 !default;

/*mixin*/
/*clearfix*/
@mixin clearfix {
    &:after {
        clear: both;
        content: '.';
        display: block;
        height: 0;
        line-height: 0;
        overflow: hidden;
    }
    *height: 1%;
}
/*inline-block*/
@mixin dib() {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}
/*文字溢出省略号*/
@mixin ell() {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/*三角箭头*/
@mixin arrow($direction, $size, $color) {
    width: 0;
    height: 0;
    line-height: 0;
    font-size: 0;
    border-width: $size;
    overflow: hidden;
    @if $direction == top {
        border-style: solid;
        border-color: transparent transparent $color transparent;
        border-top: none;
    }
    @else if $direction == bottom {
        border-style: solid;
        border-color: $color transparent transparent transparent;
        border-bottom: none;
    }
    @else if $direction == right {
        border-style: solid;
        border-color: transparent transparent transparent $color;
        border-right: none;
    }
    @else if $direction == left {
        border-style: solid;
        border-color: transparent $color transparent transparent;
        border-left: none;
    }
}
/*最小高度*/
@mixin minHeight($min-height) {
    min-height: $min-height;
    height: auto !important;
    height: $min-height;
}
/*透明度*/
@mixin opacity($opacity:0.5) {
    filter: alpha(opacity=$opacity*100);
    opacity: $opacity;
}
/*渐变*/
@mixin gradient($start, $end, $only, $angle:180deg) {
    background: -webkit-linear-gradient($angle, $start, $end);
    background: -o-linear-gradient($angle, $start, $end);
    background: -moz-linear-gradient($angle, $start, $end);
    background: linear-gradient($angle, $start, $end);
    background-color: $only;
}
/*硬件加速*/
@mixin webkitAdv() {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
/*转换*/
@mixin transform($transform...) {
    -webkit-transform: $transform;
    transform: $transform;
}
/*过渡*/
@mixin transition($transition...) {
    -webkit-transition: $transition;
    transition: $transition;
}
/*移动*/
@mixin translate($x:0, $y:0, $z:0) {
    -webkit-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}
/*缩放*/
@mixin scale($scale:1, $origin:50%) {
    -webkit-transform: scale($scale) translateZ(0);
    -webkit-transform-origin: $origin;
    transform: scale($scale) translateZ(0);
    transform-origin: $origin;
}
/*旋转*/
@mixin rotate($rotate:0deg, $origin:50%) {
    -webkit-transform: rotate($rotate) translateZ(0);
    -webkit-transform-origin: $origin;
    transform: rotate($rotate) translateZ(0);
    transform-origin: $origin;
}
/*keyframes & animation*/
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}
@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    animation: #{$str};
}